
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import t from "@common/src/i18n/t";
import FileUpload from "@/components/scope/file-upload.vue";
import any = jasmine.any;

const detecationItem = namespace("detecationItem");
const app = namespace("app");
const base = namespace("base");

@Component({
  components: { FileUpload }
})
export default class TestItemEdit extends Vue {
  @detecationItem.Action saveDetecationItem;
  @detecationItem.Action getDetecationItemDetail;
  @app.Mutation setPBPX;
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;

  propertyList: any = [{dataValue: '1', dataDesc: '正常'}, {dataValue: '2', dataDesc: '需要注意'}, {dataValue: '3', dataDesc: '需要更换'}];
  ruleOptionList: any = [];

  projectCategoryList: any=[
      {dataValue: 'C1001', dataDesc: '发动机舱'},
    {dataValue: 'C1002', dataDesc: '电器'},
    {dataValue: 'C1003', dataDesc: '轮胎'},
    {dataValue: 'C1004', dataDesc: '制动'}
  ];

  definitionOptionRange: any = {
    value: '',
    operator: '',
    operator_desc: ''
  }

  definitionOptionItem: any = {
    attrVal: "",
    text: "",
    remark: "",
    mileage_range: [],
    time_range: []
  };

  defaultProjectIconList: any = [];
  defaultHazardUrlList: any = [];

  handleAddItem() {
    let newOptionItem = Object.assign({}, this.definitionOptionItem);
    newOptionItem.mileage_range = [Object.assign({}, this.definitionOptionRange, {operator: 'gt', operator_desc: t('base.gt')}), Object.assign({}, this.definitionOptionRange, {operator: 'le', operator_desc: t('base.le')})];
    newOptionItem.time_range = [Object.assign({}, this.definitionOptionRange, {operator: 'gt', operator_desc: t('base.gt')}), Object.assign({}, this.definitionOptionRange, {operator: 'le', operator_desc: t('base.le')})];
    this.ruleOptionList.push(newOptionItem);
  }
  get breadData() {
    return [
      { name: "养车日历项目", path: "/maintenance/detecationItem/list" },
      { name: this.isAdd ? "创建项目" : "修改项目" }
    ];
  }
  get isAdd() {
    return this.$route.name === "detecationItemAdd";
  }
  mainInfoRules: any = {
    projectName: [
      {
        required: true,
        message: "请输入项目名称",
        trigger: "blur"
      }
    ],
    projectCategory: [
      {
        required: true,
        message: "请选择项目类型",
        trigger: "blur"
      }
    ]
  };
  mainInfoFormData: any = {
    projectCode: "",
    projectName: "",
    remark: "",
    projectCategory: "",
    isEnable: true,
    projectIcon: "",
    hazardUrl: "",
    ruleOptions: []
  };
  get projectCode() {
    let val = this.$route.params.projectCode;
    return (val || "");
  }
  created() {
    this.init();
  }
  init() {
    this.loadItemDetail();
  }

  fillingOptionRange(optionRange){
    if (!optionRange[0]) {
      optionRange.push(Object.assign({}, this.definitionOptionRange));
    }
    if (!optionRange[1]) {
      optionRange.push(Object.assign({}, this.definitionOptionRange));
    }
  }

  loadItemDetail() {
    if (this.projectCode.length <= 0) {
      return;
    }

    this.getDetecationItemDetail({
      projectCode: this.projectCode
    }).then(data => {
      this.mainInfoFormData = data.data;
      this.defaultProjectIconList.push({url: data.data.projectIcon});
      this.defaultHazardUrlList.push({url: data.data.hazardUrl});
      console.log("load ruleOptionList data", data.data.ruleOptions)

      for (let index in data.data.ruleOptions) {
        let ruleOption = data.data.ruleOptions[index];
        this.fillingOptionRange(ruleOption.time_range);
        this.fillingOptionRange(ruleOption.mileage_range);
        this.ruleOptionList.push(Object.assign({}, ruleOption));
      }
    });
  }



  handleDeleteRow(index) {
    this.ruleOptionList.splice(index, 1);
  }

  handleSaveItem() {
    (this.$refs.mainInfoForm as any).validate(valid => {
      if (valid && this.checkDataValid()) {
        this.saveItems();
      }
    });
  }

  filterRuleOptionList(){
    let ruleOptionList = JSON.parse(JSON.stringify(this.ruleOptionList));
    ruleOptionList.forEach((val, idx, array) => {
      if (val.time_range[1].value.length === 0) {
        val.time_range.pop();
      } else {
        val.time_range[1].operator_desc = t("base." + val.time_range[1].operator)
      }
      if (val.time_range[0].value.length === 0) {
        val.time_range.pop();
      }else {
        val.time_range[0].operator_desc = t("base." + val.time_range[0].operator)
      }
      if (val.mileage_range[1].value.length === 0) {
        val.mileage_range.pop();
      } else {
        val.mileage_range[1].operator_desc = t("base." + val.mileage_range[1].operator)
      }
      if (val.mileage_range[0].value.length === 0) {
        val.mileage_range.pop();
      } else {
        val.mileage_range[0].operator_desc = t("base." + val.mileage_range[0].operator)
      }
    });
    return ruleOptionList;
  }

  checkDataValid() {
    if (this.ruleOptionList.length < 1) {
      this.$message.error(t("setting.add-least-one-column"));
      return false;
    }
    let rulAttrValIsNull = this.ruleOptionList.some(item => {
      return !item.attrVal;
    });
    if (rulAttrValIsNull) {
      this.$message.error("请选择所有属性值");
      return false;
    }

    for (let index in this.ruleOptionList) {
      let val = this.ruleOptionList[index];
      // if (!val.time_range[1].value && val.time_range[1].operator) {
      //   this.$message.error("请填写时间范围数值");
      //   return false;
      // }
      // if (!val.time_range[0].value) {
      //
      // }
      // if (!val.mileage_range[1].value) {
      //
      // }
      // if (!val.mileage_range[0].value) {
      //
      // }
    }

    return true;
  }
  saveItems() {
    let params = Object.assign({}, this.mainInfoFormData, {
      ruleOptions: this.filterRuleOptionList()
    });
    console.log("saveParams:", params)
    this.btnStartLoading();
    this.saveDetecationItem(params)
      .then(data => {
        this.$message.success(t("base.save-success"));
        this.$router.push(`/maintenance/detecationItem/list`);
      })
      .finally(() => {
        this.btnStopLoading();
      });
  }
}
